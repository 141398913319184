
import Searcher from "../../components/Content/Searcher.vue";
import { mapGetters, mapActions } from "vuex";
import { isUndefined, isEmpty, isNull, debounce } from 'lodash';
import { parameters, parametersCreateURL } from "@/interfaces/BreadCrumbs/BreadCrumbs";
import i18n from "@/plugins/i18n";
export default {
	name: "BreadCrumbs",
	props: {
		showSearcher: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		update: true,
		menu:false,
		tooltip: false,
		progress: false,
		items: [],
		message: null,
		term: null,
		directoriesRoot: [
			"/admin/users",
			"/admin/advertisers",
			"/admin/campaigns",
			"/admin/lineitem",
			"/admin/creatives",
			"/admin/custom_lists",
			"/admin/segments",
			"/admin/modifiers",
			"/admin/requestCampaign",
			"/admin/notifications",
			"/admin/reportingOnDemand"
		],
		directoriesAll: [
			"/admin/users/index",
			"/admin/advertisers/index",
			"/admin/custom_lists/index",
			"/admin/segments/index",
			"/admin/modifiers/index",
			"/admin/requestCampaign/index"
		],
		itemsMenu:[],
		filters:{
			name: ""
		}
	}),
	components: { Searcher },
	mounted() {
		this.$nextTick(async () => {
			await this.updateBreadCrumbs();
		});
	},
	computed: {

		...mapGetters("internationalization", ["getLanguage"]),
		...mapGetters("campaign", ["getCampaign"]),
		...mapGetters("line_item", ["getLineItem"]),
		...mapGetters("creative", ["getCreative"]),
		...mapGetters("breadcrumbsM", [
			"getNavegationData",
			"getHistoryData",
			"getDataFromHistory"
		]),

		getRouter(){
			return this.$route;
		},
		getItems() {
			return this.items;
			
		},
		getTo(){
			return this.getNavegationData("to");
		},
		getFrom(){
			return this.getNavegationData("from");
		},
		getProfile() {
			return this.$store.state.profile.profile;
		},
	},
	destroyed(){
		this.itemsMenu = [];
	},
	methods: {
		...mapActions("breadcrumbsM", [
			"addHistory",
			"updateHistoryData"
		]),
		...mapActions("advertiser", ["searchAdvertiser"]),
		showTooltip(event){
			this.tooltip = true;
		},
		getPath(path: string) {
			if (this.isEditPath(path)) {
				path = path.replace(":id", this.$route.params.id);
				return path;
			}
			if (this.isRoot(path)) {
				path = path.concat("/index");
				return path;
			}
			if (this.isAdminPath(path)) {
				path = path.concat("/dashboard");
				return path;
			}
			if (this.isCreatePath(path)) {
				path = path;
				return path;
			}
		},
		isRoot(path: string) {
			return this.directoriesRoot.includes(path);
		},
		isAdminPath(path: string) {
			return String(path).endsWith("/admin");
		},
		isCreatePath(path: string) {
			return String(path).endsWith("/create");
		},
		isindexPath(path: string) {
			return String(path).endsWith("/index");
		},
		isEditPath(path: string) {
			return String(path).includes(":id");
		},
		setRoute(item: any){
			this.menu = false;
			this.term = "";
			if(this.getTo.name == "CampaignsIndex"){
				if(!isNull(this.getTo.query)){
					this.$router.replace({'query': {advertiser_id: String(item.id)}});
				}
			}
			if(this.getTo.name == "CampaignEdit"){
				if(isNull(this.getTo.query)){
					this.$router.push({name: "CampaignsIndex"});
				}else{
					this.$router.push({name: "CampaignsIndex", query: {advertiser_id: String(item.id)}});
				}
			}
			if(this.getTo.name == "LineItemList"){
				if(!isNull(this.getTo.query)){
					this.$router.replace({'query': {campaign_id: String(item.id)}});
				}
			}
			if(this.getTo.name == "CreativesIndex"){
				if(!isNull(this.getTo.query)){
					this.$router.replace({'query': {advertiser_id: String(item.id)}});
				}
			}
			if(this.getTo.name == "CreativeEdit"){
				if(isNull(this.getTo.query)){
					this.$router.push({name: "CreativesIndex"});
				}else{
					this.$router.push({name: "CreativesIndex", query: {advertiser_id: String(item.id)}});
				}
			}
		},
		syncData(value: string) {
			this.term = value;
		},
		clearQueryUrl(){
			this.menu = false;
			this.term = "";
			if(this.getTo.name == "CampaignsIndex"){
				if(!isEmpty(this.getTo.query)){
					this.$router.replace({'query': null});
				}
			}
			if(this.getTo.name == "CampaignEdit"){
				this.$router.push({name: "CampaignsIndex"});
				return;
			}
			if(this.getTo.name == "LineItemList"){
				if(!isNull(this.getTo.query)){
					this.$router.replace({'query': null});
				}
			}
			if(this.getTo.name == "CreativesIndex"){
				if(!isEmpty(this.getTo.query)){
					this.$router.replace({'query': null});
				}
			}
			if(this.getTo.name == "CreativeEdit"){
				this.$router.push({name: "CreativesIndex"});
				return;
			}
		},
		getDataObject(_data: any){
			return {
				id: _data.id,
				external_id: _data.external_id,
				name: _data.name,
			}
		},
		getUrlDashBoard(){
			let to_url = {name: "Dashboard"};
			return {
				exact: true,
				to: to_url,
				link: true,
				text: "DashBoard",
				disabled: false,
				icon: "mdi-home"
			}
		},
		getUrlAdvertisers(params: parameters){
			let to_url = params.edit ? 
			{name: "AdvertiserEdit", params: {id: params.id_entity_edit}} : 
			{name: "AdvertisersIndex"};
			return this.createURL({
				entity: "Advertiser", 
				text: params.text, 
				to: to_url, 
				history: params.history,
				query: params?.query
			});
		},
		getUrlCampaign(params: parameters){
			let to_url = params.edit ? 
			{name: "CampaignEdit", params: {id: params.id_entity_edit}} : 
			{name: "CampaignsIndex", query: {advertiser_id: String(params.query)}};
			return this.createURL({
				entity: "Campaign", 
				text: params.text, 
				to: to_url,
				history: params.history,
			});
		},
		getUrlLineItem(params: parameters){
			let to_url = params.edit ? 
			{name: "LineItemEdit", params: {id: params.id_entity_edit}} : 
			{name: "LineItemList", query: {campaign_id:  String(params.query)}};
			return this.createURL({entity: "Lineitem", text: params.text, to: to_url});
		},
		getUrlCreative(params: parameters){
			let to_url = params.edit ? 
			{name: "CreativeEdit", params: {id: params.id_entity_edit}} : 
			{name: "CreativesIndex", query: {advertiser_id: params.query}};
			return this.createURL({entity: "Creative", text: params.text, to: to_url});
		},
		createURL(params: parametersCreateURL){
			return {
				exact: true,
				to: params.to,
				link: true,
				text: params.text,
				disabled: false,
				history: params.history
			}
		},
		async getHistory(entity: string){
			return await this.getHistoryData(entity);
		},
		async updateBreadCrumbs(){
			this.items = [];
			switch(String(this.$route.name)){
				case "CampaignsIndex":
					this.items = await this.configIndexCampaign();
					break;
				case "LineItemList":
					this.items = await this.configIndexLineItem();
					break;
				case "CreativesIndex":
					this.items = await this.configIndexCreative();
					break;
				case "CampaignEdit":
					this.items = await this.configEditCampaign();
					break;
				case "LineItemEdit":
					this.items = await this.configEditLineItem();
					break;
				case "CreativeEdit":
					this.items = await this.configEditCreative();
					break;
				default:
					this.items = await this.configDefault();
					break;
			}
		},
		async configIndexCampaign(){
			if(!isNull(this.getTo.name)){
				if(!isEmpty(this.getTo.query.advertiser_id)){
					const adv = this.getDataFromHistory("advertisers", this.getTo.query.advertiser_id);
					let textAdv = `${String(adv.name)} (${String(adv.external_id)})`;
					this.itemsMenu = (await this.getHistory("advertisers")).filter((item) => item.id != adv.id);
					return [
						this.getUrlDashBoard(),
						this.getUrlAdvertisers({text: i18n.t('menu.advertiser')}),
						this.getUrlAdvertisers({
							text: textAdv, 
							id_entity_edit: String(adv.id), 
							edit: true, 
							history: true,
						}),
						this.createURL({entity: "Campaign", text: i18n.t('menu.campaign'), to: undefined})
					]
				}else{
					this.itemsMenu = await this.getHistory("advertisers");
					return [
						this.getUrlDashBoard(),
						this.getUrlAdvertisers({
							text:i18n.t('menu.advertiser'), 
							history: true,
						}),
						this.createURL({entity: "Campaign", text: i18n.t('menu.campaign'), to: undefined}),
						this.createURL({entity: "Campaign", text: i18n.t('all'), to: undefined})
					]
				}
			}else{
				this.itemsMenu = await this.getHistory("advertisers");
				return [
					this.getUrlDashBoard(),
					this.getUrlAdvertisers({
						text: i18n.t('menu.advertiser'), 
						history: true,
					}),
					this.createURL({entity: "Campaign", text: i18n.t('menu.campaign'), to: undefined}),
					this.createURL({entity: "Campaign", text: i18n.t('all'), to: undefined})
				]
			}
		},
		async configIndexLineItem(){
			if(!isNull(this.getTo.name)){
				if(!isEmpty(this.getTo.query.campaign_id)){
					let camp = this.getDataFromHistory("campaign", this.getTo.query.campaign_id);
					if(isNull(camp.advertiser)){
						let campaign = await this.show(camp.id);
						let advCamp = await this.getDataObject(campaign.advertiser);
						let params = {id: camp.id, name: camp.name, external_id: camp.external_id, advertiser: advCamp.id}
						await this.updateHistoryData({view: "campaign", data: params});
						await this.saveHistoryData("advertisers", advCamp);
					}
					camp = this.getDataFromHistory("campaign", camp.id);
					let adv = this.getDataFromHistory("advertisers", camp.advertiser);
					let textCam = `${String(camp.name)} (${String(camp.external_id)})`;
					let textAdv = `${String(adv.name)} (${String(adv.external_id)})`;
					this.itemsMenu = (await this.getHistory("campaign")).filter((item) => item.id != camp.id);
					return [
						this.getUrlDashBoard(),
						this.getUrlAdvertisers({text: i18n.t('menu.advertiser')}),
						this.getUrlAdvertisers({
							text: textAdv, 
							id_entity_edit: adv.id, 
							edit: true, 
							history: false,
						}),
						this.getUrlCampaign({
							text: i18n.t('menu.campaign'), 
							query: adv.id, 
							edit: false, 
							history: false,
						}),
						this.getUrlCampaign({
							text: textCam, 
							id_entity_edit: camp.id, 
							edit: true, 
							history: false,
						}),
						this.getUrlLineItem({
							text: i18n.t('menu.lineItem'), 
							query: camp.id, 
							edit: false, 
							history: false,
						}),
					]
				}else{
					this.itemsMenu = await this.getHistory("campaign");
					return [
						this.getUrlDashBoard(),
						this.createURL({entity: "LineItem", text: i18n.t('menu.lineItem'), to: undefined}),
						this.createURL({entity: "LineItem", text: i18n.t('all'), to: undefined})
					]
				}
			}else{
				this.itemsMenu = await this.getHistory("campaign");
				return [
					this.getUrlDashBoard(),
					this.createURL({entity: "LineItem", text: i18n.t('menu.lineItem'), to: undefined}),
					this.createURL({entity: "LineItem", text: i18n.t('all'), to: undefined})
				]
			}
		},
		async configIndexCreative(){
			if(!isNull(this.getTo.name)){
				if(!isEmpty(this.getTo.query.advertiser_id)){
					const adv = this.getDataFromHistory("advertisers", this.getTo.query.advertiser_id);
					let textAdv = `${String(adv.name)} (${String(adv.external_id)})`;
					this.itemsMenu = (await this.getHistory("advertisers")).filter((item) => item.id != adv.id);
					return [
						this.getUrlDashBoard(),
						this.getUrlAdvertisers({text: i18n.t('menu.advertiser')}),
						this.getUrlAdvertisers({
							text: textAdv, 
							id_entity_edit: String(adv.id), 
							edit: true, 
							history: true,
						}),
						this.createURL({entity: "Creatives", text: i18n.t('menu.creative'), to: undefined})
					]
				}else{
					this.itemsMenu = await this.getHistory("advertisers");
					return [
						this.getUrlDashBoard(),
						this.getUrlAdvertisers({
							text: i18n.t('menu.advertiser'), 
							history: true,
						}),
						this.createURL({entity: "Creatives", text: i18n.t('menu.creative'), to: undefined}),
						this.createURL({entity: "Creatives", text: i18n.t('all'), to: undefined})
					]
				}
			}else{
				this.itemsMenu = await this.getHistory("advertisers");
				return [
					this.getUrlDashBoard(),
					this.getUrlAdvertisers({
						text: i18n.t('menu.advertiser'), 
						history: true,
					}),
					this.createURL({entity: "Creatives", text: i18n.t('menu.creative'), to: undefined}),
					this.createURL({entity: "Creatives", text: i18n.t('all'), to: undefined})	
				]
			}
		},
		async configEditCampaign(){
			if(isNull(this.getCampaign)) return;
			if(isUndefined(this.getCampaign?.name)) return;
			let adv = await this.getDataObject(this.getCampaign.advertiser);
			let textAdv = `${String(adv.name)} (${adv.external_id})`;
			let textCam = `${String(this.getCampaign.name)} (${String(this.getCampaign.external_id)})`;
			await this.saveHistoryData("advertisers", adv);
			this.itemsMenu = await this.getHistory("advertisers");
			return [
				this.getUrlDashBoard(),
				this.getUrlAdvertisers({text: i18n.t('menu.advertiser')}),
				this.getUrlAdvertisers({
					text: textAdv, 
					id_entity_edit: adv.id, 
					edit: true, 
					history: true,
				}),
				this.getUrlCampaign({text: i18n.t('menu.campaign'), edit: false, query: adv.id}),
				this.createURL({entity: "Campaign", text: textCam, to: undefined})
			]
		},
		async configEditLineItem(){
			if(isNull(this.getLineItem)) return;
			if(isUndefined(this.getLineItem?.name)) return;
			let adv = await this.getDataObject(this.getLineItem.advertiser);
			let camp = await this.getDataObject(this.getLineItem.campaign);
			let textLineItem = `${String(this.getLineItem?.name)} (${String(this.getLineItem?.external_id)})`;
			let textAdv = `${String(adv.name)} (${String(adv.external_id)})`;
			let textCam = `${String(camp.name)} (${String(camp.external_id)})`;
			let params = {id: camp.id, name: camp.name, external_id: camp.external_id, advertiser: adv.id}
			await this.saveHistoryData("campaign", params);
			await this.saveHistoryData("advertisers", adv);
			this.itemsMenu = await this.getHistory("advertisers");
			return [
				this.getUrlDashBoard(),
				this.getUrlAdvertisers({text: i18n.t('menu.advertiser')}),
				this.getUrlAdvertisers({text: textAdv, edit: true, id_entity_edit: adv.id}),
				this.getUrlCampaign({text: i18n.t('menu.campaign'), edit: false, query: adv.id}),
				this.getUrlCampaign({text: textCam, edit: true, id_entity_edit: camp.id}),
				this.getUrlLineItem({text: i18n.t('menu.lineItem'), edit: false, query: camp.id}),
				this.getUrlLineItem({text: textLineItem, edit: true, id_entity_edit: this.getLineItem?.id}),
			]
		},
		async configEditCreative(){
			if(isNull(this.getCreative)) return;
			if(isUndefined(this.getCreative?.name)) return;
			let adv = await this.getDataObject(this.getCreative.creative_advertiser.advertiser);
			let textAdv = `${String(adv.name)} (${String(adv.external_id)})`;
			let textCre = `${String(this.getCreative?.name)} (${String(this.getCreative?.external_id)})`;
			await this.saveHistoryData("advertisers", adv);
			this.itemsMenu = await this.getHistory("advertisers");
			return [
				this.getUrlDashBoard(),
				this.getUrlAdvertisers({text: i18n.t('menu.advertiser')}),
				this.getUrlAdvertisers({text: textAdv, edit: true, id_entity_edit: adv.id, history: true}),
				this.getUrlCreative({text: i18n.t('menu.creative'), edit: false, query: String(adv.id)}),
				this.createURL({entity: "Creatives", text: textCre, to: undefined})
			]
		},
		async configDefault(){
			let self = this;
			return self.$route.matched.map((math: any, index) => {
				this.path = this.getPath(math.path);
				if(math.name == "Admin"){
					return this.getUrlDashBoard();
				}else{
					let obj = {
						exact: true,
						to: this.path,
						link: true,
						text: i18n.t(math.meta.breadcrumb),
						disabled: false,
					}
					if(index == (self.$route.matched.length - 1)){
						let value = String(self.$route.matched.at(self.$route.matched.length - 1).path);
						if(this.directoriesAll.includes(value)){
							obj.to = undefined;
							obj.text = i18n.t('all');
						}
					}
					return obj;
				}
			});
		},
		async saveHistoryData(_view: string, _data: any){
			
			let valuesHistory = await this.getHistoryData(_view);
			if(isEmpty(valuesHistory)){
				await this.addHistory({view: _view, data: _data});
				if(_view == "advertisers"){
					if(!this.itemsMenu.some((item: any) => item.id == _data.id)){
						this.itemsMenu = [...this.itemsMenu, _data];
					}
				}
			}else{
				if(!valuesHistory.some((item: any) => item.id == _data.id)){
					await this.addHistory({view: _view, data: _data});
					if(_view == "advertisers"){
						if(!this.itemsMenu.some((item: any) => item.id == _data.id)){
							this.itemsMenu = [...this.itemsMenu, _data];
						}
					}
				}
			}
		},
		async show(id: number) {
			return this.$store.dispatch("campaign/show", id, {
				root: true,
			});
		},
		async searchAdvertiserData(val: string){
			this.progress = true;
			this.filters.name = val;
			const response = await this.searchAdvertiser(this.filters);
			if(!isEmpty(response)){
				response.forEach(async (item: any) => {
					let adv = await this.getDataObject(item);
					await this.saveHistoryData("advertisers", adv);
				});
			}
			this.progress = false;
		}
	},
	watch:{
		term: debounce(async function (val: string) {
			if(!isEmpty(val)){
				await this.searchAdvertiserData(val);
			}
		}, 1000),
		async getRouter(val, old){
			if(isNull(val)) return;
			if(isNull(old)) return;
			await this.updateBreadCrumbs();
		},
		async getCampaign(val, old){
			await this.updateBreadCrumbs();
		},
		async getLineItem(val, old){
			await this.updateBreadCrumbs();
		},
		async getCreative(val, old){
			await this.updateBreadCrumbs();
		},
		async getProfile(val, old){
			this.itemsMenu = [];
		},
		async getLanguage(lang) {
			await this.updateBreadCrumbs();
		},
	}
};
